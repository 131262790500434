<template>
  <div class="ct-quotation">
    <create-quotation is-quotation> </create-quotation>
  </div>
</template>

<script>
import CreateCommonQuotation from "@/view/pages/common-quotation/Create-Quotation";

export default {
  name: "create-common-quotation",
  data() {
    return {};
  },
  components: {
    "create-quotation": CreateCommonQuotation,
  },
};
</script>
